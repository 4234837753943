import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

const StyledPortfolioContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .iframe {
    width: 100%;
    height: 100vh;
    border: none;
  }

  @media (min-width: 1280px) {
    .iframe {
      width: 1280px;
  }
`;

const ResumePage = () => {
  return (
    <>
      <Helmet title="Ayodeji's Portfolio" />
      <StyledPortfolioContainer>
        <iframe
          class="iframe"
          id="myFrame"
          src="https://public.tableau.com/views/WIP_16424100119450/Dashboard1?:showVizHome=no&amp;:embed=true"
        />
      </StyledPortfolioContainer>
    </>
  );
};

export default ResumePage;
